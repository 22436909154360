import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiClient } from '../../services/api/ApiClient'
import { Badge, Button, Card, Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap'
import moment from 'moment'
import { calculateTotal } from '../../forms/Appointment'
import { SessionModal } from './SessionModal'
import { Forms } from '../../forms/Forms'
import { formatMoney } from '../../utils/formatMoney'
import { IComment } from '../../types/models/Comment'
import { Comment } from './Comment'

export const SessionStatusBadge: React.FC<{ status: number }> = ({ status }) => {
  if (status === 0) {
    return <Badge bg='secondary' className='rounded-0'>Por Realizar</Badge>
  }
  if (status === 1) {
    return <Badge bg='danger' className='rounded-0'>Cancelada</Badge>
  }
  if (status === 2) {
    return <Badge bg='success' className='rounded-0'>Completada</Badge>
  }

}
export const Show: React.FC = () => {
  const [patient, setPatient] = useState<any>(null)
  const [showSession, setShowSession] = useState(false)
  const [currentSession, setCurrentSession] = useState<any>(null)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [errors, setErrors] = useState([])

  const handleSessionShow = (session: any) => {
    setCurrentSession(session)
    setShowSession(true)
  }

  const refresh = useCallback(() => {
    ApiClient.patients.show(id!).then((response) => {
      setPatient(response)
    })
      .catch(err => {
        setErrors(err.response.data)
      })
  }, [])

  const { id } = useParams()
  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (!isFormVisible) {
      refresh()
    }
  }, [isFormVisible])

  return (
    <>
      <div className="border p-3 mb-3 bg-secondary">
        <h1>{patient?.first_name} {patient?.last_name}</h1>
      </div>
      {isFormVisible && <Forms.Payment onClose={() => setIsFormVisible(false)} patient={{ id: patient?.id, name: `${patient?.first_name} ${patient?.last_name}` }} />}
      {showSession && <SessionModal session={currentSession} onClose={() => setShowSession(false)} patient={patient} />}
      <Container fluid >
        <div className="p-5">
          <Row>
            <Col sm={12} md={2}>
              <Card className='rounded-0'>
                <Card.Header className='bg-secondary text-light rounded-0'>Informacion del Paciente</Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Nombre</td>
                        <td>{patient?.first_name}</td>
                      </tr>
                      <tr>
                        <td>Apellido</td>
                        <td>{patient?.last_name}</td>
                      </tr>
                      <tr>
                        <td>Cedula</td>
                        <td>{patient?.ci}</td>
                      </tr>
                      <tr>
                        <td>Telefono</td>
                        <td>{patient?.phone}</td>
                      </tr>
                      <tr>
                        <td>Balance de Cuenta</td>
                        <td>{formatMoney(patient?.balance, { currency: "Bs." })}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </Col>
            <Col className='px-1'>
              <Tabs defaultActiveKey="history" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey='history' title='Historial Medico'>
                  {
                    patient?.comments.map((comment: IComment) => (
                      <Comment comment={comment} />
                    ))
                  }
                </Tab>
                <Tab eventKey='billing' title='Facturacion' className='p-2'>
                  <Row>
                    <Col sm={12} md={6} className='my-md-3'>
                      <Card className='border border-danger h-100 rounded-0'>
                        <Card.Header className=' border-danger d-flex justify-content-between align-items-center'>
                          <span>Servicios</span>
                          <Button style={{ background: 'transparent' }} disabled size='sm' className='rounded-0 text-dark' variant='dark'>Nuevo</Button> {/* variant='success' This is just to even the height*/}
                        </Card.Header>
                        <Card.Body>
                          <Table style={{ verticalAlign: 'middle' }} size='sm' striped>
                            <thead>
                              <tr>
                                <th>Fecha</th>
                                <th>Cantidad</th>
                                <th>Descripcion</th>
                                <th>Precio Unitario</th>
                                <th>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                patient?.billables.map((billable: any) => (
                                  <tr>
                                    <td>{moment(billable.created_at).toDate().toLocaleDateString()}</td>
                                    <td>{billable.quantity}</td>
                                    <td>{billable.name}</td>
                                    <td>{formatMoney(billable.price)}</td>
                                    <td>{formatMoney(calculateTotal(billable))}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>


                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={6} className='my-md-3'>
                      <Card className='border border-primary h-100 rounded-0'>
                        <Card.Header className=' border-primary d-flex justify-content-between align-items-center'>
                          <span>Pagos Realizados</span>
                          <Button onClick={() => setIsFormVisible(true)} size='sm' className='rounded-0' variant='success'>Nuevo</Button>
                        </Card.Header>
                        <Card.Body>


                          <Table style={{ verticalAlign: 'middle' }} size='sm' striped>
                            <thead>
                              <tr>
                                <th>#Ref </th>
                                <th>Fecha</th>
                                <th>Cantidad</th>
                                <th>Metodo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                patient?.payments.map((payment: any) => (
                                  <tr>
                                    <td>{payment.id}</td>
                                    <td>{moment(payment.created_at).toDate().toLocaleDateString()}</td>
                                    <td>{payment.amount}</td>
                                    <td>{payment.payment_method}</td>

                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>


                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey='appointments' title='Citas'>
                  <Table style={{ verticalAlign: 'middle' }} size='sm' striped>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Doctor</th>
                        <th>Estado</th>
                        <th>Observaciones</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        patient?.sessions.map((session: any) => (
                          <tr>
                            <td>{moment(session.date).toDate().toLocaleDateString()}</td>
                            <td>{session.doctor}</td>
                            <td><SessionStatusBadge status={session.status} /></td>
                            <td>{session.comment}</td>
                            <td>
                              {session.status == 2 && <Button onClick={() => handleSessionShow(session)} size='sm' className='rounded-0' variant='outline-primary'>Comprobante</Button>}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey='images' title='Imagenologia'>

                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>

      </Container>
    </>
  )
}